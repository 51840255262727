import { taxRateService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { TaxRateResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { taxRate } from '@/utils/validate';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'add-tax-rate'
})
export default class AddTaxRate extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public taxRate!: TaxRateResource | null;

  public taxRateForm: {
    categoryName: string;
    taxRate: number | null;
  } = {
    categoryName: '',
    taxRate: null
  };

  public resourceFormRules = {
    categoryName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('taxRate.inputClassify')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          taxRateService
            .checkTaxClassifyName(value, parseFloat(this.$route.query.invoiceId as string), this.taxRate?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('taxRate.taxClassifyRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    taxRate: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.inputTaxRate')));
            return;
          }
          if (!taxRate(value.toString())) {
            callback(new Error(translation('invoice.taxRateValid')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (!this.taxRate) {
      this.operationType = 'add';
      this.title = 'taxRate.addTaxRate';
      return;
    }
    this.operationType = 'edit';
    this.title = 'taxRate.editTaxRate';
    this.$nextTick(() => {
      Object.assign(this.taxRateForm, this.taxRate);
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.taxRateForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.taxRateForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await taxRateService.post({
            ...this.taxRateForm,
            invoiceId: parseFloat(this.$route.query.invoiceId as string)
          } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await taxRateService.put({
          ...this.taxRateForm,
          invoiceId: parseFloat(this.$route.query.invoiceId as string)
        } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.taxRateForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
